const GetIcon = ({ icon, color }) => {
    switch (icon) {
        case 'add':
            return <svg
                width="1280pt" height="1280pt" viewBox="0 0 1280 1280"
                preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0,1280) scale(0.1,-0.1)"
                    fill={color} stroke="none">
                    <path d="M5150 12613 c-45 -7 -134 -57 -176 -98 -43 -41 -84 -102 -100 -145
   -5 -14 -11 -952 -14 -2225 l-5 -2200 -2200 -5 c-1256 -3 -2211 -9 -2225 -14
   -80 -29 -171 -114 -215 -200 l-30 -59 0 -1271 0 -1271 28 -56 c16 -31 39 -67
   51 -80 38 -40 85 -79 96 -79 5 0 10 -4 10 -8 0 -4 26 -17 58 -27 56 -20 103
   -20 2245 -23 l2187 -2 0 -2188 c0 -2306 -2 -2206 44 -2291 23 -44 105 -131
   122 -131 8 0 14 -4 14 -10 0 -5 5 -10 11 -10 6 0 25 -6 42 -13 86 -37 82 -37
   1307 -37 880 0 1213 3 1239 12 73 23 121 43 121 51 0 4 6 7 13 7 20 0 102 90
   127 140 12 25 28 54 34 65 8 15 12 612 14 2212 l2 2193 2188 3 c2049 2 2190 3
   2230 20 54 22 120 65 149 97 39 43 43 49 43 60 0 5 5 10 10 10 6 0 10 6 10 13
   0 6 8 26 18 42 16 28 17 111 17 1305 0 1194 -1 1277 -17 1305 -10 17 -18 36
   -18 43 0 6 -4 12 -10 12 -5 0 -10 5 -10 11 0 29 -127 132 -190 155 -14 5 -967
   11 -2220 14 l-2195 5 -5 2190 c-5 1927 -7 2194 -21 2225 -46 106 -105 174
   -194 222 l-60 33 -1250 1 c-687 1 -1261 0 -1275 -3z"/>
                </g>
            </svg>

        case 'tg': return <svg xmlns="https://www.w3.org/2000/svg" width="30px" height="30px"
            viewBox="0 0 533.06 532.01">
            <path fill="#293a4c" d="M266.15,532C119.72,532.34-1,412.15,0,264.05,1,118.68,120.65-1.32,269.13,
                0,414.38,1.31,533.8,120.36,533.06,267.77,532.32,413.51,412.86,532.16,266.15,532Z"/>
            <path fill="#d0d0df" d="M390,181.63c-1.49,11.51-3.12,26.53-5.42,41.45q-7.52,48.71-15.64,97.3C365.66,
                340,362.6,359.7,358,379.09a46.68,46.68,0,0,1-3.36,9.36c-4.46,9.36-11.6,12.63-21.71,
                10.33-6.46-1.48-12.05-4.73-17.45-8.36Q277.23,364.6,239,338.73c-15.86-10.75-16.76-18.45-3.1-31.63,
                21.07-20.34,42.5-40.32,63.7-60.53,5.42-5.17,10.58-10.6,15.8-16,2.45-2.5,5.65-5.34,
                2.88-9-3.11-4.08-6.76-1-9.7.83q-20.61,13.18-41,26.71-32.64,21.71-65.13,43.63c-8.61,
                5.78-17.36,11.2-28.08,12.09-8.46.7-16.54-1.52-24.51-3.71-11.7-3.22-23.25-7-34.82-10.64a45.53,
                45.53,0,0,1-7.34-3.11c-8.54-4.45-9.14-10.73-1.48-16.54a42,42,0,0,1,8.59-5c74.25-32,148.08-65,
                223.33-94.55,9.59-3.77,19.22-7.49,29.29-9.87.65-.15,1.28-.39,1.93-.52C383.89,158.23,390.1,163.39,
                390,181.63Z"/>
        </svg>
        case 'mail': return <svg xmlns="https://www.w3.org/2000/svg" width="30px" height="30px"
            viewBox="0 0 511.49 511.51">
            <path fill="#293a4c" d="M0,270.37V240.44c.81-8.63.91-17.31,1.54-26,1.22-16.73,6.11-32.57,
        11.88-48.15C24.3,136.92,39.7,110,60.54,86.59c21.32-24,45.63-44.39,74.44-59.15,20.66-10.57,
        42-19,64.76-23.87,10.84-2.33,21.9-2.24,32.87-3.08,2.61-.2,5.28.6,7.83-.49h29.93c8.95.88,17.94,1.05,
        26.91,1.7,16.28,1.17,31.62,6,46.74,11.55,29.62,10.88,56.72,26.34,80.28,47.36,24.1,21.5,44.6,46,59.41,
        75,11.49,22.51,20.16,45.91,24.94,70.75,1.37,7.15,1.62,14.41,2,21.62,1.21,23.57,1.43,47.2-1.31,70.65-2.6,
        22.29-10,43.33-19.31,63.69C479.8,384.7,467,405.45,450.6,423.83c-20.23,22.74-43.06,42.46-70,57-24,13-49.15,
        22.71-76,27.76-7.39,1.39-14.88,1.67-22.33,2.05-23.16,1.19-46.38,
        1.35-69.43-1.24-22.13-2.48-43.06-9.77-63.3-18.93-22.85-10.34-44.08-23.39-62.79-40.13-23.7-21.2-44-45.17-58.79-73.62C17.09,
        355.84,8.46,334.15,3.59,311.05c-2.43-11.49-2.5-23.18-3.11-34.83C.38,274.27,1,272.24,0,270.37Z"/>
            <path fill="#d0d0df" d="M289,301c-8.24,10.16-16.92,18.56-28,24.1-15.51,7.79-31.91,10.56-48.87,
        6.7-24.13-5.5-40.41-26.89-41.68-52.12-2.53-50.48,34-98.63,88.95-110,23-4.77,45.6-3,68,3.21,8.44,2.35,11.93,8.47,
        10.21,17.11q-8.51,42.73-16.9,85.49a52.05,52.05,0,0,0-.48,17.87c.85,5.55,3.47,7.76,9,8,9.12.41,16.42-3.6,
        22.67-9.71,12-11.75,17.79-26.6,20.51-42.83,3.5-20.91,
        2.06-41.53-5.76-61.26-12.12-30.56-35.29-48.57-67.07-55-40.79-8.27-78.79-1.52-111.83,
        25-27.27,21.88-41.59,51.21-46.15,85.5-2.77,20.85-2,41.57,5.31,61.48,13.76,37.32,41.21,58.5,79.79,65.62,27,5,
        53.26,1.5,78.87-7.88,6.85-2.51,7.27-2.4,10.66,4.26,1.66,3.25,3.28,6.53,4.75,9.87,2.69,6.09,1.83,8.15-4.36,
        10.67a156.38,156.38,0,0,1-40.85,10.57c-24.94,2.95-49.68,
        2.68-74.08-4.51-57.12-16.82-94.08-65.68-94-125.24.08-53.18,19.7-97.47,61.77-130.74,26-20.56,56.23-30.79,
        89.16-33.44,26.6-2.14,52.5.65,76.92,11.91,40.48,18.68,63.75,50.44,69.46,94.61,3.45,26.63.31,52.51-13,
        76.29-13.7,24.44-34.22,39.27-62.58,42.29a53.48,53.48,0,0,1-16.37-.82c-11.39-2.34-19.05-8.92-22.23-20.32C290.38,
        305.72,289.83,303.81,289,301Z"/>
            <path fill="#293a4c" d="M211.51,270.17c.53-39.42,33-71.08,71.35-69.54.66,0,1.33,0,2,.09,10.41.9,11.61,2.48,9.55,
        12.84-2.3,11.55-4.25,23.2-7.09,34.62C282.5,267.44,272,283,255.12,293.82c-7.08,4.53-14.9,6.62-23.3,5.12C218.36,
        296.54,211.3,286.39,211.51,270.17Z"/>
        </svg>
        case 'support': return <svg className="support_svg"
         xmlns="https://www.w3.org/2000/svg" width="300" height="300" viewBox="0 0 320.36 362.08">
            <path fill="#293a4c" d="M231.16,334A118.88,118.88,0,0,1,197,353.89c-54.5,20.58-114.24,
                1.49-148.23-47.24A167.8,167.8,0,0,1,32,276.81c-.64-1.49-1.06-3-2.91-3.74-17.2-7.32-24.46-21.87-28-39a65.3,
                65.3,0,0,1-1-13.4c0-21.94.18-43.89,0-65.83C-.71,70.48,60.62,16.65,123,3.52c25.74-5.41,51.13-5.23,75.77,
                5.11,8.84,3.71,16.6,9.07,22.35,16.94a4.79,4.79,0,0,0,4,2.4C249.56,29.2,267,42.22,280.24,61.68c13.63,20,
                20.53,42.53,24.1,66.26,2.61,17.36,1.76,34.83,1.84,52.26,0,3,.48,5.09,3.21,7a24.32,24.32,0,0,1,10.09,
                16.55,82.58,82.58,0,0,1,.11,23.34,25.19,25.19,0,0,1-11.29,18,8.74,8.74,0,0,0-3.9,5.91c-6.71,31.75-34.47,
                54.78-66.82,55-30.25.23-60.5.09-90.75,0-12.23,0-21.16-8.52-21.51-20.17s8.2-20.75,20.29-21.48a131,131,0,
                0,1,15,0c7.86.42,14,4.08,17.7,11.08,1.12,2.09,2.23,2.83,4.54,2.82,18.78-.13,37.58.32,56.35-.31,16.16-.55,
                27.58-9.25,35-23.46,1.45-2.78,1.84-4.33-2.39-4.22-7.33.19-7.27,0-7.35-7.56-.22-21.93,
                1-43.9-2-65.74-4.16-30.88-13.55-59.77-32.42-85-2.07-2.78-4.44-5.35-6.9-8.28a9.55,9.55,0,0,0-1.29,
                1.63c-11.83,26.79-33.06,42.44-60.32,51-18.59,5.83-37.87,8.33-56.91,12-10.75,2.07-21.36,4.56-31.53,
                8.85-20.54,8.65-30.13,24.58-31,46.22-.17,4.08-.7,5.82-5.39,5.59-8.74-.44-8.78-.11-8.74,8.62,0,5-.27,
                10,1,14.88,2.54,9.83,8.42,16.46,18.42,18.79,3.81.88,5.58,2.67,6.95,6.41,11.13,30.46,29.54,54.72,59.89,
                68.4a87.64,87.64,0,0,0,35.74,7.77c25.26.16,50.53.09,75.8.11Z"/>
        </svg>
        default: return <div></div>
    }



}

export default GetIcon